import React, { useState } from 'react'
import SideMenu from './layouts/SideMenu'
import TopBar from './layouts/TopBar'
import MobileMenu from './layouts/MobileMenu'
import { DataGrid } from '@mui/x-data-grid';
import GeneralReport from './layouts/GeneralReport';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Modal } from '@mui/material';
import './cssClasses/custom.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getBearerToken, postBearerToken, putBearerToken } from '../../utils/Apis';
import { useNavigate } from 'react-router-dom';

const A_active_user_list = () => {
    React.useEffect(() => {
        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();
        return () => {
            // document.body.removeChild(externalScript);
        };
    }, []);


    const navigate = useNavigate()
    const [rows, setrows] = React.useState([])
    const [rendered, setrendered] = useState(0)
    
    React.useEffect(() => {
        const intervalId = setInterval(() => {
            fetch_users()
        }, 10000);
        return () => {
            clearInterval(intervalId);
        };
    }, [rendered])


    const editAdmin = async (id, type) => {
        if (type == "E") {
            navigate("/edit_sub_admin", { state: { id: id } })
        } else {
            let current_date = new Date();
            putBearerToken("users?_id=" + id, {
                deleted_at: current_date,
                slug_name: current_date + id,
                client_id: current_date + id,
                client_secret: current_date + id,
                oauth_id: current_date + id,
                s2s_client_id: current_date + id,
                s2s_client_secret: current_date + id,
            }).then((result) => {
                if (result.error == 0) {

                    setrendered(o => o + 1)

                } else {


                }

            })

        }

    }

    const fetch_users = async () => {
        let pathname = window.location.pathname
        let room_id = pathname.split("/")[2]
        getBearerToken("fetch_active_users_of_room?room=" + room_id).then((result) => {
            if (result.error == 0) {
                console.log(result.data)
                setrows(result.data)
            } else {
                // notify(JSON.stringify(result.messages))
            }
        })
    }


    const columns = [
        {
            field: 'first_name',
            headerName: 'First Name',
            headerClassName: "grid-header",
            flex: 1,

        },
        {
            field: 'phone',
            headerName: 'Phone',
            headerClassName: "grid-header",
            flex: 1,

        },
        {
            field: 'slug_name',
            headerName: 'User Name',
            headerClassName: "grid-header",
            flex: 1,

        },


        {
            field: 'type',
            headerName: 'Type',
            headerClassName: "grid-header",
            flex: 1,
            valueGetter: (params) => params.row.type == "SubAdmin" ? "Host" : "User"

        },

        {
            field: 'mute_status',
            headerName: 'Mute Status',
            headerClassName: "grid-header",
            flex: 1,
            renderCell: (params) => (
                <div className={params.row.mute_status == true ? "status-bg-green" : "status-bg-red"}>
                    {params.row.mute_status == true ? "Mute" : "Unmute"}
                </div>
            )
        },


    ];


    const users = [
        { first_name: 'John', phone: '1234567890', slug_name: 'john_doe', type: 'admin', mute_status: true },
        { first_name: 'Jane', phone: '0987654321', slug_name: 'jane_doe', type: 'user', mute_status: false },
        { first_name: 'Alex', phone: '1112223334', slug_name: 'alex_smith', type: 'moderator', mute_status: true },
        { first_name: 'Mary', phone: '5556667778', slug_name: 'mary_jane', type: 'guest', mute_status: false },
        // Add more user data as needed
    ];


    return (
        <div className="py-5 md:py-0">
            {/* <!-- BEGIN: Mobile Menu --> */}
            <div className="mobile-menu md:hidden">
                {/* <MobileMenu /> */}

            </div>
            {/* <!-- END: Mobile Menu -->
        <!-- BEGIN: Top Bar --> */}
            {/* <TopBar level1={"User Management"} /> */}
            {/* <!-- END: Top Bar --> */}
            <div className="flex overflow-hidden">
                {/* <!-- BEGIN: Side Menu --> */}
                {/* <SideMenu /> */}
                {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
                <div className="content">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-12">

                            <div class="grid grid-cols-12 gap-6 mt-5">
                                <div class="intro-y col-span-12 lg:col-span-6">

                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-6">
                                {/* <!-- BEGIN: General Report --> */}

                                <div className="col-span-12 mt-6">
                                    <div className="intro-y block sm:flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            Active Users
                                        </h2>

                                    </div>

                                    <div className="user-list-container">
                                        {rows.map((user, index) => (
                                            <div className="user-box" key={index}>
                                                {/* Left side: Big mute/unmute status */}
                                                <div className={`mute-status text-white ${user.mute_status ? 'muted bg-red' : 'unmuted bg-success'}`}>
                                                    <span className={`mute-icon ${user.mute_status ? 'icon-muted-large' : 'icon-unmuted-large'}`}>
                                                        {user.mute_status ? '🔇' : '🎤'}
                                                    </span>
                                                    <div>{user.mute_status ? 'Muted' : 'Unmuted'}</div>
                                                </div>

                                                {/* Right side: Only First Name */}
                                                <div className="userboxcontent">
                                                    <div className="user-field"><strong></strong> {user.first_name}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                {/* <!-- END: Weekly Top Products --> */}
                            </div>
                        </div>

                    </div>


                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default A_active_user_list